import MomentUtils from "@date-io/moment";
import {
	Box,
	Fab,
	InputAdornment,
	makeStyles,
	Tooltip,
	Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LocationOffIcon from "@material-ui/icons/LocationOff";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SaveIcon from "@material-ui/icons/Save";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {
	DatePicker,
	MuiPickersUtilsProvider,
	TimePicker,
} from "@material-ui/pickers";
import {
	EditNoteLod,
	FileArray,
	FormikAutoComplete,
	MuiSelect,
	MuiSwitch,
	MuiTextInput,
} from "components";
import { Option } from "components/FormikAutoComplete";
import theme from "config/theme";
import { Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { InformationManagementMarkerDTO, ProtectiveMarkingDTO } from "services";
import { useStore } from "store";
import {
	EditNoteAdditionalAttachmentInput,
	EditNoteInput,
} from "store/noteStore";
import { GetNoteTitle, GetTimeDateString, MapMarkingsToSelect } from "utils";
import { AUTOSAVE_INTERVAL } from "../config/SETTINGS";
import { checkPermission } from "../store/rbac";
import { KeywordFieldArray } from "./KeywordFieldArray";
import { FormikErrors } from "formik/dist/types";
import moment from "moment";
import { PhoneIphone } from "@material-ui/icons";

// utilities and consts
const declaredStartStyle = { width: "125px" };
const useStyles = makeStyles({
	fab_2: {
		position: "absolute",
		bottom: theme.spacing(8),
		right: theme.spacing(2),
	},
});

const setDate = (next: Date, current?: Date): Date => {
	const updated = current ? current : new Date();
	updated.setDate(next.getDate());
	updated.setMonth(next.getMonth());
	updated.setFullYear(next.getFullYear());
	return updated;
};

const setTime = (next: Date, current?: Date): Date => {
	const updated = current ? current : new Date();
	updated.setHours(next.getHours());
	updated.setMinutes(next.getMinutes());
	return updated;
};

// props interfaces
interface EditNoteFormProps {
	handleSubmit: (e: any) => void;
	initialValues: EditNoteInput;
	newNote: boolean;
	getOptions: (value: string) => Promise<any[]>;
	setEditing: (editing: boolean) => void;
	editing: boolean;
}

interface RenderEditFormProps {
	formikProps: FormikProps<EditNoteInput>;
	newNote: boolean;
	getOptions: (value: string) => Promise<any[]>;
	setEditing: (editing: boolean) => void;
	editing: boolean;
	protectiveMarkings: ProtectiveMarkingDTO[];
	managementMarkers: InformationManagementMarkerDTO[];
}

// components
const Title = ({ title, noteCreated, ownerEmail, ownerName }: any) => (
	<Box display="flex" justifyContent="space-between" paddingTop="4px">
		<Box display="flex">
			<Typography variant="h5">{GetNoteTitle(title)}</Typography>
		</Box>
		<Box display="flex" paddingTop="4px">
			<Typography align="right" variant="body1">
				{noteCreated}
				<a href={`mailto:${ownerEmail}`}>{ownerName}</a>
			</Typography>
		</Box>
	</Box>
);

const RenderEditForm = ({
	formikProps: { values, errors, ...formikEvents },
	newNote,
	protectiveMarkings,
	managementMarkers,
	setEditing,
	editing,
	getOptions,
}: RenderEditFormProps) => {
	// Refs
	const titleRef = useRef<HTMLInputElement>();
	const bodyRef = useRef<HTMLInputElement>();
	const autoSaveRef = useRef(0);
	const fileArrayRef = useRef(null);

	// hooks
	const classes = useStyles();
	const [initialSurpression, setInitialSurpression] = useState(
		values.surpressLocation
	);

	const {
		applicationStore,
		noteStore,
		userStore: { userId, isLawyer, userRole },
	} = useStore();

	const last5addresses: Option[] = noteStore.lastLocations.map((x) => ({
		name: x.name,
		position: { lon: x.longitude, lat: x.latitude },
	}));

	const last5URNs: Option[] = noteStore.lastURN.map((x) => ({
		name: x,
	}));

	const { saving, isBrowserBlockingLocationRequest, GetPosition } = noteStore;

	useEffect(() => {
		if (editing) {
			setInitialSurpression(values.surpressLocation);
		}
	}, [editing]);

	useEffect(() => {
		if (editing && titleRef && bodyRef) {
			window.setTimeout(() => {
				if (!values?.title) {
					titleRef.current?.focus();
				} else {
					bodyRef.current?.focus();
				}

				if (!values.id) {
					getAndSetLocation();
				}
			}, 100);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editing]);

	useEffect(() => {
		if (
			editing &&
			applicationStore &&
			formikEvents &&
			!autoSaveRef.current
		) {
			autoSaveRef.current = window.setInterval(() => {
				const shouldSave = applicationStore.autosave();
				if (shouldSave && !saving) {
					formikEvents.handleSubmit();
				}
			}, AUTOSAVE_INTERVAL);
		} else if (autoSaveRef.current) {
			window.clearInterval(autoSaveRef.current);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editing]);

	const getAndSetLocation = () => {
		console.log("Getting the location of the current user");
		// get declared location
		GetPosition().then((currPos) => {
			if (!!currPos) {
				noteStore.decodeAddress(currPos.coords).then((address) => {
					formikEvents.setFieldValue("surpressLocation", false);
					formikEvents.setFieldValue("declaredLocation", {
						name: address,
						position: {
							lat: currPos.coords.latitude,
							lon: currPos.coords.longitude,
						},
					});
				});
			}
		});
	};

	useEffect(() => {
		const saveBeforeUnload = (ev: any) => {
			if (editing && !saving) {
				console.log(`auto save before navigating.....`, values);
				ev.preventDefault();
				return formikEvents.handleSubmit();
			}
		};

		window.addEventListener("beforeunload", saveBeforeUnload);

		return function () {
			// clean up event handlers
			window.clearInterval(autoSaveRef.current);
			window.removeEventListener("beforeunload", saveBeforeUnload);
		};
	}, []);

	// Events Handlers
	const editCurrentNote = () => {
		setEditing(true);
	};

	const saveForm = async () => {
		// we need to call the child component method to upload the pending file
		await (fileArrayRef?.current as any)?.uploadFile();
		setEditing(false);
		formikEvents.handleSubmit();
	};

	const updateDeclareStart = (d: Date) => {
		formikEvents.handleChange({
			target: {
				value: d,
				id: "declaredStart",
				name: "declaredStart",
			},
		});
	};
	const updateDeclareDate = (e: any) => {
		updateDeclareStart(setDate(e.toDate(), values.declaredStart));
	};

	const updateDeclareTime = (e: any) => {
		updateDeclareStart(setTime(e.toDate(), values.declaredStart));
	};

	// members
	const allowLocationChange = newNote;

	const markings = useMemo(() => MapMarkingsToSelect(protectiveMarkings), [
		protectiveMarkings,
	]);

	const imm = useMemo(() => MapMarkingsToSelect(managementMarkers, "None"), [
		managementMarkers,
	]);

	const locLabel = useMemo(() => {
		const userOwnsNote = newNote || userId?.toString() === values.ownerId;
		const your = userOwnsNote ? "your " : "";
		const not = values.surpressLocation ? "not " : "";

		if (!editing) {
			return "";
		}

		if (newNote || (!values.surpressLocation && initialSurpression)) {
			if (!!values.surpressLocation && isBrowserBlockingLocationRequest) {
				return `${your}location is blocked by the browser`;
			}

			return `${your}location will ${not}be saved`;
		}

		// When user didn't change any settings
		if (!initialSurpression) {
			return `${your}location has been saved`;
		}

		return `${your}location has not been saved`;
	}, [
		newNote,
		editing,
		values.surpressLocation,
		initialSurpression,
		userId,
		values.ownerId,
		isBrowserBlockingLocationRequest,
	]);

	const isImmRequired = useMemo(() => {
		const selectedPm = Number(values.protectiveMarkingId);
		return (
			protectiveMarkings?.find((pm) => pm.id === selectedPm)
				?.isIMMRequired || false
		);
	}, [values.protectiveMarkingId, protectiveMarkings]);

	const immVal = useMemo(() => {
		return isImmRequired
			? values.informationManagementMarkerId?.toString()
			: "";
	}, [values.informationManagementMarkerId, isImmRequired]);

	const userCan = useMemo(() => {
		const permissionData = {
			userId,
			ownerId: newNote ? userId : values.ownerId,
			isLawyer,
		};

		const result = {
			edit: checkPermission(userRole, "UpdateData", permissionData),
			editClassificationFields: checkPermission(
				userRole,
				"UpdateClassificationField",
				permissionData
			),
			editOtherFields: checkPermission(
				userRole,
				"UpdateNonClassificationField",
				permissionData
			),
		};
		return result;
	}, [userRole, userId, values.ownerId, isLawyer, values.id, newNote]);
	
	const mobileNote = useMemo(() => {
		let note = ""
		if (values.clientCreatedTime) {
			note += "Created on mobile: " + moment(values.clientCreatedTime).format("DD/MM/YYYY h:mma") + "\n"
		}
		if (values.clientUpdatedTime?.getTime() !== values.clientCreatedTime?.getTime()) {
			note += "Edited on mobile: " + moment(values.clientUpdatedTime).format("DD/MM/YYYY h:mma") + "\n"
		}
		note += "Last Synced time: " + moment(values.lastModified).format("DD/MM/YYYY h:mma")

		return note
	}, [values])

	const disableClassificationFields = !editing || !userCan.editOtherFields;

	// render
	if (!values) {
		return <></>;
	}
	if (values.id === -1) {
		return <EditNoteLod />;
	}
	const onSupressLocationChange = () => {
		if (!values.surpressLocation) {
			formikEvents.setFieldValue("surpressLocation", true);
			// clear
			formikEvents.setFieldValue("declaredLocation", {
				name: "",
				position: null,
			});
		} else {
			getAndSetLocation();
		}
	};
	return (
		<Form style={{ padding: "48px" }}>
			<div className="form-editnote">
				<Box>
					{editing ? (
						<MuiTextInput
							inputRef={titleRef}
							disabled={!userCan.editOtherFields}
							variant="standard"
							id="title"
							default=""
							error={!!errors.title}
							placeHolder="Start typing your title here…"
							value={values.title}
							formikEvents={{ ...formikEvents }}
							type="text"
							rows={1}
							inputProps={{
								style: {
									fontSize: "x-large",
								},
								endAdornment: (
									<InputAdornment position="end">
										{`${
											!newNote
												? `Created - ${GetTimeDateString(
														values.created || new Date()
												  )} by`
												: ""
										} ${values.ownerName || ""}`}
									</InputAdornment>
								),
							}}
						/>
					) : (
						<Title
							title={values.title}
							noteCreated={`Created - ${GetTimeDateString(
								values.created || new Date()
							)} by `}
							ownerEmail={values.ownerEmail}
							ownerName={values.ownerName}
						/>
					)}
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						paddingTop="16px"
					>
						<Box display="inline-block">
							<MuiSwitch
								id="isPrivate"
								canEdit={userCan.editOtherFields}
								editing={editing}
								onChange={() =>
									formikEvents.setFieldValue(
										"isPrivate",
										!values.isPrivate
									)
								}
								value={values.isPrivate || false}
								label={
									editing
										? values.isPrivate
											? "Private"
											: "Not Private"
										: ""
								}
								yesIcon={<VisibilityOffIcon color="action" />}
								noIcon={<VisibilityIcon color="action" />}
							/>

							<MuiSwitch
								id="surpressLocation"
								canEdit={
									allowLocationChange &&
									userCan.editOtherFields
								}
								editing={editing}
								onChange={onSupressLocationChange}
								value={!values.surpressLocation}
								label={locLabel}
								yesIcon={<LocationOnIcon color="primary" />}
								noIcon={<LocationOffIcon color="error" />}
							/>

							{!editing && (!!values.clientCreatedTime || !!values.clientUpdatedTime) &&
								<Tooltip 
									title={mobileNote}
									classes={{
										tooltip: "whitespace-pre"
									}}
								>
									<label className="MuiFormControlLabel-root">
										<div style={{ marginLeft: '16px' }}>
											<PhoneIphone color="primary"/>
										</div>
									</label>
								</Tooltip>
							}
							
						</Box>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<Box display="inline-block">
								<Box
									display="flex"
									flexDirection="column"
									alignItems="flex-end"
								>
									<DatePicker
										disabled={disableClassificationFields}
										style={declaredStartStyle}
										disableFuture
										error={!!errors.declaredStart}
										id="date-picker"
										label="Incident Date"
										value={values.declaredStart}
										onChange={updateDeclareDate}
									/>
								</Box>
								<Box
									display="flex"
									flexDirection="column"
									alignItems="flex-end"
								>
									<TimePicker
										disabled={disableClassificationFields}
										style={declaredStartStyle}
										error={!!errors.declaredStart}
										id="time-picker"
										label="Incident Time"
										value={values.declaredStart}
										onChange={updateDeclareTime}
										helperText={errors.declaredStart}
									/>
								</Box>
							</Box>
						</MuiPickersUtilsProvider>
					</Box>
					<Box paddingTop="16px">
						<MuiTextInput
							inputRef={bodyRef}
							disabled={disableClassificationFields}
							variant="standard"
							id="body"
							placeHolder={
								editing ? "Start typing your note here…" : ""
							}
							value={values.body}
							default=""
							formikEvents={{ ...formikEvents }}
							type="text"
							title=""
							rows={10}
						/>
					</Box>
					<Box display="flex" width="100%" paddingTop="16px">
						{/* Specification Fields, only Lawyer can update other users' note */}
						<Box display="inline-block" width="50%">
							<MuiSelect
								canEdit={userCan.editClassificationFields}
								editing={editing}
								id="protectiveMarkingId"
								value={
									values.protectiveMarkingId?.toString() || ""
								}
								default={markings[0] ? markings[0].value : `0`}
								formikEvents={{ ...formikEvents }}
								type="text"
								title="Protective Marking"
								items={markings}
							/>
						</Box>
						<Box display="inline-block" width="50%">
							<MuiSelect
								canEdit={
									userCan.editClassificationFields &&
									Number(values.protectiveMarkingId) >= 3
								}
								disabled={!isImmRequired}
								editing={editing && isImmRequired}
								id="informationManagementMarkerId"
								value={immVal}
								default={imm?.[0]?.value || `0`}
								formikEvents={{ ...formikEvents }}
								type="text"
								title="IMM/DLM"
								items={imm}
							/>
						</Box>
					</Box>
					<Box display="flex" width="100%" paddingTop="16px">
						<Box display="inline-block" width="50%">
							<FormikAutoComplete
								getOptions={(val) => {
									// no search required
									return Promise.resolve([]);
								}}
								intialValues={last5URNs}
								title="Unique Reference Number"
								disabled={!userCan.editOtherFields || !editing}
								type="text"
								value={
									{
										...values.declaredUrn,
									} as Option
								}
								id="declaredUrn"
								formikEvents={{ ...formikEvents }}
							/>
						</Box>
						<Box display="inline-block" width="50%">
							<FormikAutoComplete
								getOptions={getOptions}
								intialValues={last5addresses}
								title="Incident Location"
								disabled={disableClassificationFields}
								type="text"
								error={!!errors.declaredLocationError}
								value={{ ...values.declaredLocation } as Option}
								id="declaredLocation"
								formikEvents={{ ...formikEvents }}
							/>
						</Box>
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						width="100%"
						paddingTop="16px"
					>
						<KeywordFieldArray
							title="Event Tags"
							editing={editing}
							canEdit={userCan.editOtherFields}
							name="keywords"
							values={values.keywords}
						/>
					</Box>
					<FileArray
						ref={fileArrayRef}
						canEdit={userCan.editOtherFields}
						editing={editing}
						preUpload={(fileBlob, fileName) => {
							if (!newNote) {
								return Promise.resolve();
							}

							// Make sure we save the form before uploading to get the ID
							formikEvents.setFieldValue("newNoteAttachment", {
								fileBlob,
								fileName,
							} as EditNoteAdditionalAttachmentInput);
							formikEvents.handleSubmit();

							return Promise.reject();
						}}
						// don't save the note when new recording is added
						save={() => {}}
						files={values.attachments ? values.attachments : []}
					/>
				</Box>
			</div>

			{userCan.edit && (
				<Fab
					color="primary"
					variant="extended"
					size="small"
					disabled={editing && !formikEvents.isValid}
					aria-label="Edit"
					className={classes.fab_2}
					onClick={() => (editing ? saveForm() : editCurrentNote())}
				>
					{editing ? <SaveIcon /> : <EditIcon />}
					{editing ? "Save" : "Edit"}
				</Fab>
			)}
		</Form>
	);
};

export const RenderForm = observer(RenderEditForm);

export const NoteForm = ({
	handleSubmit,
	setEditing,
	initialValues,
	newNote,
	getOptions,
	editing,
}: EditNoteFormProps) => {
	const {
		noteStore: { protectiveMarkings, managementMarkers },
	} = useStore();

	// Form validation
	const validateForm = (
		values: EditNoteInput
	): void | FormikErrors<EditNoteInput> => {
		// Validate date
		const isFutureDate = moment(values.declaredStart).isAfter();

		const errors = {
			...(isFutureDate && {
				declaredStart: "Incident time cannot be in the future",
			}),
			...(!values.title && {
				title: "Title is required",
			}),
			...(!values.declaredLocation?.name && {
				declaredLocationError: "Location is required",
			}),
		};

		if (Object.keys(errors).length > 0) {
			return errors;
		}
	};

	return (
		<Formik
			validateOnMount
			initialValues={initialValues}
			enableReinitialize
			onSubmit={handleSubmit}
			validateOnChange
			validate={validateForm}
		>
			{({ ...e }) => (
				<RenderForm
					protectiveMarkings={protectiveMarkings}
					managementMarkers={managementMarkers}
					editing={editing}
					setEditing={setEditing}
					getOptions={getOptions}
					newNote={newNote}
					formikProps={{ ...e }}
				/>
			)}
		</Formik>
	);
};

export const EditNoteForm = observer(NoteForm);
