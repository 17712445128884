import {
	Button,
	IconButton,
	makeStyles,
	TextField,
	Typography,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import moment from "moment";
import { KeyEventIsEnter } from "utils";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import MicIcon from "@material-ui/icons/Mic";
import React, {
	useRef,
	useState,
	forwardRef,
	useImperativeHandle,
	useEffect,
} from "react";
import { useStore } from "store";
import { ViewMedia } from "./ViewMedia";
import { VoiceRecorder } from "./VoiceRecorder";
import { AttachmentDTO } from "services";

const styles = makeStyles({
	recButton: {
		minWidth: "300px",
		height: "50px",
		display: "flex",
		justifyContent: "space-around",
		margin: "25px 0px",
	},
	fileName: {
		alignSelf: "center",
		minWidth: "450px",
	},
	fileEditor: {
		minWidth: "300px",
		display: "flex",
		justifyContent: "center",
	},
	mediaContainer: {
		paddingBottom: "100px",
	},
});

interface MediaFileControlProps {
	addFile: (file: string | Partial<AttachmentDTO>) => void;
	preUpload?: (fileBlob: Blob, fileName: string) => Promise<void>;
	submit: () => void;
}

export const MediaFileControl = forwardRef(
	(props: MediaFileControlProps, ref) => {
		const classes = styles();

		const [unsavedFile, setUnsavedFile] = useState(false);
		const [audioFile, setAudioFile] = useState(false);
		const [fileBlob, setFileBlob] = useState<Blob | null>();
		const [blobUrl, setBlobUrl] = useState<string>("");
		const [fileName, setFileName] = useState<string | null>();
		const [uploading, setUploading] = useState(false);
		const {
			fileStore,
			noteStore: { activeNote },
		} = useStore();
		const fileSelect: any = useRef(null);
		const recorderRef = useRef<HTMLDivElement>(null);
		const [percentUpload, setPercentUpload] = useState(0);
		
		useEffect(() => {
			fileStore.setCallBackPercentUpload(setPercentUpload)
		}, [])

		const clickFileSelect = (): void => {
			fileSelect.current.click();
			fileSelect.current.value = "";
		};

		const onSelectFile = (e: any): void => {
			if (e.target.files) {
				const f = e.target.files[0];
				onSaveFile(f);
				setFileName(f.name);
				setUnsavedFile(true);
			}
		};

		const onSaveFile = (file: Blob) => {
			setFileBlob(file);
			setBlobUrl(URL.createObjectURL(file));

			if (audioFile) {
				const fileName = moment(new Date()).format(
					"YYYY-MM-DD [at] h:mm A z"
				);
				setFileName(`Audio Recording ${fileName}`);
			}
			recorderRef?.current?.scrollIntoView(true);
		};

		const cancelAddFile = () => {
			setFileBlob(null);
			setFileName(null);
			setUnsavedFile(false);
			setUploading(false);
			setAudioFile(false);
		};

		const discardAudio = () => {
			setUnsavedFile(false);
			setAudioFile(false);
		};

		const onUploadFile = async () => {
			if (fileBlob && fileName) {
				setUploading(true);
				try {
					props.preUpload &&
						(await props.preUpload(fileBlob, fileName));
				} catch {
					// Cancel upload if preUpload hook exists and rejected
					return;
				}

				// Don't upload if there's no activeNote
				if (!activeNote?.id) {
					console.error("No active note found - cannot upload file");
					return;
				}
				setPercentUpload(0);
				await fileStore
					.uploadFile(fileBlob, fileName, activeNote.id)
					.then((e) => {
						props.addFile({
							fileName: e.filename,
							attachmentGuid: e.id,
						});
						cancelAddFile();
						props.submit();
					});
			}
		};

		useImperativeHandle(ref, () => ({
			// parent component will need to call this method when note is save
			uploadFile() {
				return onUploadFile();
			},
		}));
		const FileUploadButton = () => (
			<Button
				variant="contained"
				aria-label="Attach-file"
				onClick={() => clickFileSelect()}
				startIcon={<AttachFileIcon />}
			>
				Add File
			</Button>
		);

		if (uploading) {
			return <div>Uploading ...({percentUpload}%)</div>;
		}

		return (
			<>
				<div className={classes.mediaContainer}>
					<input
						id="files"
						style={{ display: "none" }}
						type="file"
						ref={fileSelect}
						onChange={onSelectFile}
					/>
					{!unsavedFile && (
						<div className={classes.recButton}>
							<Button
								variant="contained"
								aria-label="new-recording"
								onClick={() => {
									recorderRef?.current?.scrollIntoView(true);
									setUnsavedFile(true);
									setAudioFile(true);
								}}
								startIcon={<MicIcon />}
							>
								Add Recording
							</Button>
							<FileUploadButton />
						</div>
					)}
					{unsavedFile && (
						<Typography align="center" variant="h6">
							{audioFile ? "New Recording" : "New Upload"}
							{fileName && `- ${fileName}`}
						</Typography>
					)}
					{unsavedFile && !fileName && !fileBlob && audioFile && (
						<VoiceRecorder
							save={onSaveFile}
							discard={discardAudio}
						/>
					)}
					{unsavedFile && fileBlob && (
						<>
							<ViewMedia
								isAudio={audioFile}
								isImage={fileBlob.type.includes("image")}
								blobUrl={blobUrl}
								newRecording={true}
							/>
							<div className={classes.fileEditor}>
								<TextField
									label="Filename"
									autoFocus
									className={classes.fileName}
									defaultValue={fileName}
									onKeyDown={(e) =>
										KeyEventIsEnter(e) && onUploadFile()
									}
									onChange={(e) => {
										setFileName(e.target.value);
									}}
								/>
								<IconButton
									color={!fileName ? "secondary" : "primary"}
									size="small"
									disabled={!fileName}
									onClick={onUploadFile}
								>
									<DoneIcon />
									Save
								</IconButton>
								<IconButton
									color="default"
									size="small"
									onClick={cancelAddFile}
								>
									<DeleteIcon />
									Discard
								</IconButton>
							</div>
						</>
					)}
				</div>
				{/* this is used so that we can scroll into view */}
				<div ref={recorderRef}></div>
			</>
		);
	}
);
